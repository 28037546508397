<template>
	<div class="links-box flex flex-column">
        <a href="https://weibo.com/u/7962756685" target="_blank" class="link-item">
            <img src="../assets/links/weibo.png" alt="微博" class="link-icon">
            <span>微博</span>
        </a>
        <a href="https://www.xiaohongshu.com/user/profile/673a9cd8000000001c01ad7d?xsec_token=YBLdNRsI1X1YYeEuz5EqJ3l8m3lS35qaNvt5fc3gie4-E=&xsec_source=app_share&xhsshare=CopyLink&appuid=673a9cd8000000001c01ad7d&apptime=1734335647&share_id=cc7fec3cf87a4808a0606fb803f9ac13" target="_blank" class="link-item">
            <img src="../assets/links/xiaohongshu.png" alt="小红书" class="link-icon">
            <span>小红书</span>
        </a>
        <a href="https://b23.tv/glBXpIM" target="_blank" class="link-item">
            <img src="../assets/links/bilibili.png" alt="哔哩哔哩" class="link-icon">
            <span>哔哩哔哩</span>
        </a>
        <div class="link-item flex-jc-center" @click="scrollToTop">
            <i class="el-icon-arrow-up"></i>
        </div>
	</div>
</template>

<script>
export default {
	data() {
		return {

		};
	},
	methods: {
        scrollToTop() {
            window.scrollTo({
                top: 0,
                behavior: 'smooth'
            });
        }

        
	}
};
</script>

<style scoped lang="scss">
.links-box {
    position: fixed;
    right: 10px;
    bottom: 240px;
    z-index: 1000;
    border: 1px solid rgba(255, 255, 255, .5);
    background: #fff;
    .link-item {
        width: 126px;
        height: 38px;
        position: relative;
        margin-bottom: 1px;
        background: #000;
        display: flex;
        color: #ffffff;
        align-items: center;
        &:last-child {
            margin-bottom: 0;
        }
        .link-icon {
            width: 30px;
            height: 30px;
            margin: 0 10px;
        }
    }
}
</style>