<template>
	<footer>
        <!-- <div class="footer-links-wrapper">
            <div class="footer">
                <div class="about">关于我们</div>
                <div class="footer-links flex flex-jc-center">
                    <a href="https://weibo.com/u/7962756685" target="_blank" class="link-icon">
                        <img src="../assets/links/weibo.png" alt="微博" >
                    </a>
                    <a href="https://www.xiaohongshu.com/user/profile/673a9cd8000000001c01ad7d?xsec_token=YBLdNRsI1X1YYeEuz5EqJ3l8m3lS35qaNvt5fc3gie4-E=&xsec_source=app_share&xhsshare=CopyLink&appuid=673a9cd8000000001c01ad7d&apptime=1734335647&share_id=cc7fec3cf87a4808a0606fb803f9ac13" target="_blank" class="link-icon">
                        <img src="../assets/links/xiaohongshu.png" alt="小红书" class="link-icon">
                    </a>
                    <a href="https://b23.tv/glBXpIM" target="_blank" class="link-icon">
                        <img src="../assets/links/bilibili.png" alt="哔哩哔哩" class="link-icon">
                    </a>
                    <el-popover
                        placement="top"
                        width="160"
                        trigger="hover">
                        <img slot="reference" src="../assets/links/gongzhonghao2.png" alt="公众号" class="link-icon">
                        <img src="../assets/links/gongzhonghao.jpg" alt="公众号" width="160px" >
                    </el-popover>
                    <el-popover
                        placement="top"
                        width="240"
                        trigger="hover">
                        <img slot="reference" src="../assets/links/douyin.png" alt="公众号" class="link-icon" style="margin-right: 0;">
                        <img src="../assets/links/douyin.jpg" alt="公众号" width="240px" >
                    </el-popover>
                </div>
            </div>
        </div> -->
        <div class="footer-wrapper">
            <div class="footer flex flex-ai-center flex-jc-between">
                <div class="footer-infos">
                    <ul class="footer-infos-list flex flex-wrap">
                        <li class="info">联系方式：15338947719</li>
                    </ul>
                    <ul class="footer-infos-list flex flex-wrap">
                        <li class="info">公司地址：海南省三亚市天涯区凤凰路163号南方航空城7栋3A</li>
                    </ul>
                    <ul class="footer-infos-list flex flex-wrap">
                        <li class="info">WHS世雄格斗 © 2024 All rights reserved whs888.com</li>
                    </ul>
                    <ul class="footer-infos-list flex flex-wrap">
                        <li class="info cursor" @click="handleLicense">营业执照</li>
                        <li class="info">
                            <a href="https://beian.miit.gov.cn/" target="_blank" style="color: unset;">琼ICP备2024048418号-1</a>
                        </li>
                    </ul>
                </div>
                <div class="flex flex-ai-center code-wrapper">
                    <div class="flex flex-column flex-ai-center">
                        <img src="../assets/links/gongzhonghao.jpg" alt="公众号" width="140px" >
                        <div style="color:#ffffff;font-size: 14px;line-height: 40px;">微信公众号</div>
                    </div>
                    <div class="flex flex-column flex-ai-center">
                        <img src="../assets/links/douyin2.jpg" alt="抖音" width="140px" >
                        <div style="color:#ffffff;font-size: 14px;line-height: 40px;">抖音</div>
                    </div>
                </div>
            </div>
        </div>
	</footer>
</template>

<script>
export default {
	data() {
		return {

		};
	},
	methods: {
        handleLicense () {
            this.$router.push('/license');
        }

	}
};
</script>

<style scoped lang="scss">
ol, ul {
    list-style: none;
    padding: 0;
}
// .about {
//     font-family: "microsoft yahei";
//     text-align: center;
//     width: 100%;
//     font-size: 24px;
//     color: #363636;
// }
// .footer-links-wrapper {
//     padding-bottom: 44px;
//     padding-top: 104px;
// }
// .footer-links {
//     font-size: 14px;
//     line-height: 14px;
//     padding-top: 44px;
// }
// .footer-links-list, .footer-infos-list {
//     padding-left: 0;
//     margin-bottom: 0;
// }
// .footer-links .link:not(:last-child) {
//     margin-right: 20px;
// }
.footer-wrapper {
    margin-top: 40px;
}
.footer {
    padding: 30px 0;
}
.footer-infos-list:not(:last-child) {
    margin-bottom: 20px;
}
.footer-infos {
    padding-bottom: 32px;
    padding-top: 32px;
    font-size: 14px;
    line-height: 14px;
}
.footer-infos .info:not(:last-child) {
    margin-right: 20px;
    padding-right: 20px;
    border-right: 1px solid #ffffff;
}
.footer-infos .info, .footer-links .link {
    transition: all 0.4s ease;
}
.footer-infos-list .cursor {
    cursor: pointer;
}
// .footer-infos .info:hover, .footer-links .link:hover, .footer-links .link a:hover {
//     color: rgb(187, 187, 187) !important;
// }
.link-icon {
    width: 40px;
    height: 40px;
    margin-right: 40px;
    img {
        width: 100%;
        height: 100%;
    }
}
.code-wrapper {
    > * {
        margin: 0 20px;
    }
}
</style>