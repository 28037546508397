<template>
    <div id="app">
        <Header />
        <div class="body">
            <keep-alive>
                <router-view v-if="$route.meta.keepAlive" :key="key" />
            </keep-alive>
            <router-view v-if="!$route.meta.keepAlive" :key="key" />
        </div>
        <Footer />
        <Links />
    </div>
</template>

<script>
const debounce = (fn, delay) => {
    let timer
    return (...args) => {
        if (timer) {
            clearTimeout(timer)
        }
        timer = setTimeout(() => {
            fn(...args)
        }, delay)
    }
}
const _ResizeObserver = window.ResizeObserver;
window.ResizeObserver = class ResizeObserver extends _ResizeObserver{
    constructor(callback) {
    callback = debounce(callback, 200);
    super(callback);
    }
}

import Header from '@/components/Header.vue';
import Footer from '@/components/Footer.vue';
import Links from '@/components/Links.vue';
export default {
    components: { Header, Footer, Links },
    name: "App",
    computed: {
        key() {
            return this.$route.fullPath;
        }
    }
};
</script>

<style>
#app {
    font-family: Avenir, Helvetica, Arial, sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    /* background-image: url(~/src/assets/bg.png); */
    background-size: cover;
    background-repeat: no-repeat;
}
body, html { margin: 0; padding: 0; overflow-x: hidden; }
.body { min-height: calc(100vh - 60px - 310px); }
</style>
