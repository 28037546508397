import Vue from 'vue'
import Router from 'vue-router'

Vue.use(Router)

const routes = [
    { 
        path: '/',
        redirect: '/home' 
    },
    {
        path: '/home',
        name: 'home',
        component: () => import('@/pages/home/index.vue'),
        meta: { keepAlive: true, active: '/home' }
    },
    {
        path: '/match',
        name: 'match',
        component: () => import('@/pages/match/index.vue'),
        meta: { keepAlive: true, active: '/match' }
    },
    {
        path: '/schedule',
        name: 'schedule',
        component: () => import('@/pages/schedule/index.vue'),
        meta: { keepAlive: true, active: '/schedule' }
    },
    {
        path: '/player-weights',
        name: 'player-weights',
        component: () => import('@/pages/player/weights.vue'),
        meta: { keepAlive: true, active: '/player-weights' }
    },
    {
        path: '/player-list',
        name: 'player-list',
        component: () => import('@/pages/player/list.vue'),
        meta: { keepAlive: true, active: '/player-weights' }
    },
    {
        path: '/player-detail',
        name: 'player-detail',
        component: () => import('@/pages/player/detail.vue'),
        meta: { keepAlive: false, active: '/player-weights' }
    },
    {
        path: '/rank-weights',
        name: 'rank-weights',
        component: () => import('@/pages/rank/weights.vue'),
        meta: { keepAlive: true, active: '/rank-weights' }
    },
    {
        path: '/rank-list',
        name: 'rank-list',
        component: () => import('@/pages/rank/list.vue'),
        meta: { keepAlive: true, active: '/rank-weights' }
    },
    {
        path: '/replay-index',
        name: 'replay-index',
        component: () => import('@/pages/replay/index.vue'),
        meta: { keepAlive: true, active: '/replay-index' }
    },
    {
        path: '/replay-list',
        name: 'replay-list',
        component: () => import('@/pages/replay/list.vue'),
        meta: { keepAlive: true, active: '/replay-index' }
    },
    {
        path: '/replay-detail',
        name: 'replay-detail',
        component: () => import('@/pages/replay/detail.vue'),
        meta: { keepAlive: false, active: '/replay-index' }
    },
    {
        path: '/signUp',
        name: 'signUp',
        component: () => import('@/pages/signUp/index.vue'),
        meta: { keepAlive: true, active: '/signUp' }
    },
    {
        path: '/news',
        name: 'news',
        component: () => import('@/pages/news/index.vue'),
        meta: { keepAlive: true, active: '/news' }
    },
    {
        path: '/news-detail',
        name: 'news-detail',
        component: () => import('@/pages/news/detail.vue'),
        meta: { keepAlive: false, active: '/news' }
    },
    {
        path: '/license',
        name: 'license',
        component: () => import('@/pages/license/index.vue'),
        meta: { keepAlive: true }
    }
]

const router = new Router({
    routes
})

export default router