<template>
	<div class="header-wrapper">
		<div class="header flex flex-ai-center">
			<img class="logo" src="../assets/logo4.png"  />
			<div>
				<el-menu
					:default-active="defaultActive"
					mode="horizontal"
					@select="handleSelect"
					background-color="transparent"
					text-color="#aaaaaa"
					active-text-color="#FFFFFF"
					router
				>
					<el-menu-item index="/home">首页</el-menu-item>
					<el-menu-item index="/match">赛事板块</el-menu-item>
					<el-menu-item index="/schedule">赛程安排</el-menu-item>
					<el-menu-item index="/player-weights">签约选手</el-menu-item>
					<el-menu-item index="/rank-weights">排行榜</el-menu-item>
					<el-menu-item index="/replay-index">精彩回放</el-menu-item>
					<el-menu-item index="/news">新闻</el-menu-item>
					<el-menu-item index="/signUp">报名通道</el-menu-item>
					
					<!-- <el-submenu index="2">
						<template slot="title">
							<router-link to="/match">赛事板块</router-link>
						</template>
						<el-menu-item index="/2-1">赛事分区1</el-menu-item>
						<el-menu-item index="/2-2">赛事分区2</el-menu-item>
						<el-menu-item index="/2-3">赛事分区3</el-menu-item>
					</el-submenu>
					<el-submenu index="/3">
						<template slot="title">赛程安排</template>
						<el-menu-item index="/3-1">赛事分区1</el-menu-item>
						<el-menu-item index="/3-2">赛事分区2</el-menu-item>
						<el-menu-item index="/3-3">赛事分区3</el-menu-item>
					</el-submenu>
					<el-submenu index="/4">
						<template slot="title">签约选手</template>
						<el-menu-item index="/4-1">蝇量级</el-menu-item>
						<el-menu-item index="/4-2">雏量级</el-menu-item>
						<el-menu-item index="/4-3">羽量级</el-menu-item>
						<el-menu-item index="/4-4">轻量级</el-menu-item>
						<el-menu-item index="/4-5">次中量级</el-menu-item>
						<el-menu-item index="/4-6">中量级</el-menu-item>
						<el-menu-item index="/4-7">女子草量级</el-menu-item>
						<el-menu-item index="/4-8">女子蝇量级</el-menu-item>
					</el-submenu>
					<el-submenu index="/5">
						<template slot="title">排行榜</template>
						<el-menu-item index="/5-1">蝇量级</el-menu-item>
						<el-menu-item index="/5-2">雏量级</el-menu-item>
						<el-menu-item index="/5-3">羽量级</el-menu-item>
						<el-menu-item index="/5-4">轻量级</el-menu-item>
						<el-menu-item index="/5-5">次中量级</el-menu-item>
						<el-menu-item index="/5-6">中量级</el-menu-item>
						<el-menu-item index="/5-7">女子草量级</el-menu-item>
						<el-menu-item index="/5-8">女子蝇量级</el-menu-item>
					</el-submenu>
					<el-submenu index="/6">
						<template slot="title">赛程安排</template>
						<el-menu-item index="/6-1">赛事分区1</el-menu-item>
						<el-menu-item index="/6-2">赛事分区2</el-menu-item>
						<el-menu-item index="/6-3">赛事分区3</el-menu-item>
					</el-submenu>
					<el-menu-item index="/7">报名通道</el-menu-item> -->
				</el-menu>
			</div>
		</div>
	</div>
</template>

<script>
export default {
	data() {
		return {
			defaultActive: '',

		};
	},
	methods: {
		handleSelect (key, keyPath) {
			console.log(key, keyPath);
		}

	},
	watch: {
		//监听路由变化
		$route:{
			handler:function (to) {
				this.defaultActive = to.meta.active;
			},
			immediate: true
		}
	},
};
</script>

<style scoped lang="scss">
.header-wrapper {
	height: 100px;
	padding-top: 15px;
	box-sizing: border-box;
}
.logo { width: auto; height: 70px; margin: 0 150px; }
.el-menu {
	a {
		color: inherit;
		text-decoration:none;
	}
}
.el-menu--horizontal>.el-menu-item {
	// width: calc(100% / 8);
	text-align: center;
}
.el-dropdown-menu__item, .el-menu-item {
	font-size: 18px;
}
// .el-menu--horizontal > .el-menu-item { color: rgba($color: #ffffff, $alpha: 0.7) !important; }
.el-menu.el-menu--horizontal { border-bottom: none; }
// .el-menu--horizontal>.el-menu-item.is-active {
// 	color: #FFBD00 !important;
// 	border-bottom: 1px solid #FFBD00 !important;
// }
.el-menu--horizontal>.el-menu-item:not(.is-disabled):focus, .el-menu--horizontal>.el-menu-item:not(.is-disabled):hover, .el-menu--horizontal>.el-submenu .el-submenu__title:hover {
	background-color: transparent !important;
}
.el-menu--horizontal>.el-menu-item.is-active {
	border-bottom-width: 3px;
}
</style>